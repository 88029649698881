import React, { useState, Fragment, useEffect } from "react";
import gql from "graphql-tag";
import "bootstrap/dist/css/bootstrap.css";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import queryString from "query-string";
import LoadingOverlay from "react-loading-overlay";
import { Alert } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import { Button, Col, Row } from "react-bootstrap";
import {
  Address1,
  Address2,
  City,
  Email,
  FirstName,
  LastName,
  Phone,
  State,
  Zip,
} from "./formItems";
import "antd/dist/antd.css";
import { Form } from "antd";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";

const Styles = styled.div`
  .OrangeBtn {
    font-weight: 500;
    background-color: #f18b21;
    color: hsla(0, 0%, 100%, 1);
  }
  .OrangeBtn:hover {
    background-color: #e2620e;
    color: hsla(0, 0%, 100%, 1);
  }
  .bg-bannerHome {
    background-image: url("images/1200x627_banner no logo (1).png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
  }

  .bg-SunPowerBlue {
    background-color: rgba(0, 118, 190, 0.6);
    color: #ffffff important!;
    text-align: center;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
  }

  .OrangeLink {
    color: #f18b21;
  }
  .OrangeLink:hover {
    color: #e2620e;
  }

  .BlueLink {
    color: #0076be;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }

  body {
    color: hsla(0, 0%, 30%, 1);
  }

  main {
    min-height: 100vh;
    padding-bottom: 60px;
  }

  footer {
    height: 50px;
    margin-top: -50px;
    width: 100%;
  }
`;

const query = gql`
  query PhysicalCardAddress($token: String!) {
    physicalCardAddress(token: $token) {
      fname
      lname
      email
      phone
      address1
      address2
      city
      state
      zip
      status
    }
  }
`;

const REDEEM_PHYSICAL_CARD = gql`
  mutation PhysicalCardRedeem(
    $token: String!
    $fname: String
    $lname: String
    $email: String
    $phone: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zip: String
  ) {
    physicalCardRedeem(
      input: {
        token: $token
        fname: $fname
        lname: $lname
        email: $email
        phone: $phone
        address1: $address1
        address2: $address2
        city: $city
        state: $state
        zip: $zip
      }
    ) {
      message
      error
    }
  }
`;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BPS_SYSTEM_URL,
});

const clientForBpsSystem = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

const RedeemPhysicalCard = (props) => {
  const values = queryString.parse(props.location.search);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showSpinner2, setShowSpinner2] = useState(false);
  const [spinnerText, setSpinnerText] = useState({
    text: "Redirecting to Cardholder site...",
  });
  const [showErrorMessage, setshowErrorMessage] = useState(false);
  const [hideFields, setHideFields] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const [form] = Form.useForm();


  /*useQuery(query, {
    client: clientForBpsSystem,
    variables: { token: values.token },
    skip: !values.token,
    onCompleted(data) {
      setShowSpinner(false);
      if (data?.physicalCardAddress.status !== "success") {
        setshowErrorMessage(true);
        seterrorMessage(data?.physicalCardAddress?.status || "Something went wrong!");
        if (
          data?.physicalCardAddress.status ===
          "This token has already been redeemed and cannot be redeemed again"
        ) {
          setHideFields(true);
          seterrorMessage(
            "The mailing address has already been confirmed for this payment."
          );
        }
      } else {
        form.setFieldsValue(data.physicalCardAddress);
        setshowErrorMessage(false);
      }
    },
  });*/
  const mqSiteUrl = process.env.REACT_APP_MQ_URL

  useEffect(() => {
    if (mqSiteUrl) {
      // Redirect to the specified URL if REACT_APP_MQ_URL is present
      const baseUrl = process.env.REACT_APP_MQ_URL
      window.location.replace(`${baseUrl}/create-account?redemptionToken=${values.token}&type=visareward`)
    }
  }, [values.token, mqSiteUrl])

  const [redeemPhysicalCard] = useMutation(REDEEM_PHYSICAL_CARD, {
    client: clientForBpsSystem,
    onCompleted(data) {
      setShowSpinner2(false);
      if (data.physicalCardRedeem.error) {
        setshowErrorMessage(true);
        seterrorMessage(data.physicalCardRedeem.message);
      } else {
        window.location = "/physicalcardredeemed";
      }
    },
  });

  const handleClick = (formValues) => {
    setShowSpinner2(true);
    setSpinnerText({ text: "Your card request is being processed..." });
    
    redeemPhysicalCard({
      variables: {
        token: values.token,
        fname: formValues.fname,
        lname: formValues.lname,
        email: formValues.email,
        phone: formValues.phone.replace(/[\(\)\-\s]/g, ''),
        address1: formValues.address1,
        address2: formValues.address2,
        city: formValues.city,
        state: formValues.state,
        zip: formValues.zip,
      },
    });
  };

  return (
    <Fragment>
      <LoadingOverlay
        active={showSpinner || showSpinner2}
        spinner
        text={spinnerText.text}
      >
        <Styles>
          <main>
            <div className="container-fluid">
              <img
                src="images/SunPower.png"
                width="180px"
                alt="SunPower logo"
              />
            </div>
            <div className="container">
              <div className="row justify-content-center bg-bannerHome text-white text-center">
                <div className="col-lg-8 bg-SunPowerBlue align-self-center p-4">
                  <img
                    src="images/SP-CardRedesignG.png"
                    width="200px"
                    alt="Visa"
                    className="pb-2"
                  />
                  <h1>Visa Reward Card Confirmation</h1>
                </div>
              </div>
            </div>
            <br />

            <div className="container">
              <div className="row">
                <div className="col">
                  <Alert
                    color="danger"
                    style={{ display: showErrorMessage ? "block" : "none" }}
                  >
                    {ReactHtmlParser(errorMessage)}
                  </Alert>
                </div>
              </div>
              <Row>
                <Form
                  form={form}
                  className="w-100 gx-3 m-2 justify-content-center"
                  layout="vertical"
                  requiredMark={false}
                  onFinish={handleClick}
                >
                  <h4 className="fs-20 fw-bold bps-midnight">
                    Your Information
                  </h4>
                  <Row>
                    <Col md={6}>
                      <FirstName />
                    </Col>
                    <Col md={6}>
                      <LastName />
                    </Col>
                    <Col md={6}>
                      <Email />
                    </Col>
                    <Col md={6}>
                      <Phone />
                    </Col>
                  </Row>

                  <h4 className="fs-20 fw-bold bps-midnight pt-4">
                    Mailing Address
                  </h4>
                  <Row>
                    <Col md={6}>
                      <Address1 />
                    </Col>
                    <Col md={6}>
                      <Address2 />
                    </Col>
                    <Col md={6}>
                      <City />
                    </Col>
                    <Col md={3}>
                      <State />
                    </Col>
                    <Col md={3}>
                      <Zip />
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col>
                      <div style={{ display: hideFields ? "none" : "block" }}>
                        <Button
                          type="submit"
                          className="ant-btn ant-btn-default OrangeBtn col-md-2 mt-4"
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Row>
            </div>
          </main>
        </Styles>
      </LoadingOverlay>
    </Fragment>
  );
};

export default RedeemPhysicalCard;
