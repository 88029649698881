import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
//import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from 'apollo-link';
import RedeemPhysicalCard from './Components/RedeemPhysicalCard';
import PhysicalCardRedeemed from './Components/PhysicalCardRedeemed';
import { isIE } from 'react-device-detect'

let client = '';
if (!isIE) {
  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_NODE_URL,
  });
  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('currentUser');
    // return the headers to the context so httpLink can read them
    const token = localStorage.getItem('currentUser');
    
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });
  
  const wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_NODE_URL_WSS}`,
    options: {
      reconnect: true,
      /*connectionParams: {
        authToken: localStorage.getItem('isAuth'),
      }*/
    }
  })
  
  /*wsLink.subscriptionClient.on('connecting', () => {
    console.log('connecting');
  });
  
  wsLink.subscriptionClient.on('connected', () => {
    console.log('connected');
  });
  
  wsLink.subscriptionClient.on('reconnecting', () => {
    console.log('reconnecting');
  });
  
  wsLink.subscriptionClient.on('reconnected', () => {
    console.log('reconnected');
  });
  
  wsLink.subscriptionClient.on('disconnected', () => {
    console.log('disconnected');
  });*/
  
  wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () => wsLink.subscriptionClient.maxConnectTimeGenerator.max;
  
  
  const errorlink = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
  
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      console.log(response);
      //response.errors = null;
      
    }
  });
  
  const httpLinkErrHandling = ApolloLink.from([
    errorlink,
    httpLink,
  ])
  
  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    authLink.concat(httpLinkErrHandling)
  )
  /*const client = new ApolloClient({
    link: logoutLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache()
  });*/
  client = new ApolloClient({
    link: link,
    cache: new InMemoryCache()
  });
  
}

class App extends React.Component {
  constructor(props) {
      super(props);

  }

  render() {
    if (isIE) {
      return (
        <div>
          <h1>Hi there. You’re using an outdated browser</h1>
          <p>For a safer and faster user experience use a modern browser like Chrome, Firefox, Safari, Opera, or Edge.</p>
        </div>
      );
    }
    
    return (
      <ApolloProvider client={client}>
        <React.Fragment>
          <Router>
            <Route exact path="/redeemphysicalcard" component={RedeemPhysicalCard} />
            <Route exact path="/physicalcardredeemed" component={PhysicalCardRedeemed} />
          </Router> 
        </React.Fragment>
      </ApolloProvider>
    );
    
  }
}
export default App;
