import React, { useState, Fragment } from "react";
import styled from 'styled-components';
import { Col, Container, Row } from "react-bootstrap";

const Styles = styled.div`
    .imp-label {
        color: red;
        text-align:center;
    }
    .sub-label {
        font-weight: bold;
        text-align:center;
    }
    .center-text {
        text-align:center;
    }
    .OrangeBtn {
        font-weight: 500;
        background-color: #F18B21;
        color: hsla(0,0%,100%,1.00);
    }
    .OrangeBtn:hover {
        background-color: #E2620E;
        color: hsla(0,0%,100%,1.00);
    }
    .bg-bannerHome {
        background-image: url("images/1200x627_banner no logo (1).png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 300px;
    }

    .bg-SunPowerBlue {
        background-color: rgba(0,118,190,0.60);
        color:#FFFFFF important!;
        text-align: center;
    }
    


    h1, h2, h3, h4, h5 {
        font-weight: 400;
    }

    .OrangeLink {
        color: #F18B21;
    }
    .OrangeLink:hover {
        color: #E2620E;
    }

    .BlueLink {
        color: #0076BE;
    }
    .material-icons.md-18 { font-size: 18px; }
    .material-icons.md-24 { font-size: 24px; }
    .material-icons.md-36 { font-size: 36px; }
    .material-icons.md-48 { font-size: 48px; }

    body{
        color: hsla(0,0%,30%,1.00);
    }


    main {
        min-height: 100vh;
        padding-bottom: 60px;
    }

    footer{
        height: 50px;
        margin-top: -50px;
        width: 100%;
    }
`;

const PhysicalCardRedeemed = (props) => {

    const [showSuccessMessage, setshowSuccessMessage] = useState(true);

    return(
        <Fragment>
            <Styles>
            <main>
	            <div className="container-fluid"><img src="images/SunPower.png" width="180px" alt="SunPower logo" /></div>
                <div className="container">
                    <div className="row justify-content-center bg-bannerHome text-white text-center">
                        <div className="col-lg-8 bg-SunPowerBlue align-self-center p-4">
                            <img src="images/SP-CardRedesignG.png" width="200px" alt="Visa" className="pb-2" />
                            <h1>Visa Reward Card Confirmation</h1>
                        </div>
                    </div>
                </div>
                <br />
	  
                <Container>

                    <Row className="py-5 bps-bg-light justify-content-center text-center">
                        <Col md={6} className="pt-5">
                            <h2 className="bps-midnight fs-48">Visa Reward Card Confirmation</h2>
                            <p className="fs-20">
                            You should receive your card in 10-15 business days.
                            </p>
                        </Col>
                    </Row>
                    
                </Container>
            </main>
            </Styles>
        </Fragment>
    );
}

export default PhysicalCardRedeemed;
